@charset "UTF-8";

/*
#styleguide
.m-btn1

汎用ボタン1
```
<div class="mB20">
  <a href="" class="m-btn1 m-btn1-en m-btn1-white m-btn1-middle1">MORE</a>
</div>
<div class="mB20">
  <button class="m-btn1 m-btn1-arrow-right m-btn1-max">buttonタグのボタン</button>
</div>
<div class="mB20">
  <input type="submit" class="m-btn1 m-btn1-arrow-right" value="input[type=submit]、input[type=button]のボタンはarrow-rightに非対応">
</div>
```
*/
.m-btn1 {
  display: inline-block;
  font-family: $Gothic;
  transition: all .4s ease;
  @include f-size();
  font-weight: 500;
  line-height: 1.6em;
  text-align: center;
  @include bpTb {
    // width: 100%;
    // max-width: 500px;
    @include f-size($baseFontSp);
  }
  @include bpSp {
    // width: 80%;
  }

  &:hover {
    text-decoration: none;
  }

  // @at-root {
  //   input[type=submit].m-btn1,
  //   input[type=button].m-btn1 {
  //     border: 2px solid $color1;
  //     border-radius: 3px;
  //   }
  // }

  &-en {
    font-family: $Lato;
    font-weight: 700;
    letter-spacing: 0.06em;
  }

  &-white {
    background-color: #fff;
    border: 2px solid $textColor;
    color: $textColor;
    &:hover {
      background-color: $textColor;
      color: #fff;
      text-decoration: none;
    }
  }
  &-black {
    background-color: $textColor;
    border: 2px solid $textColor;
    color: #fff;
    &:hover {
      background-color: #fff;
      color: $textColor;
      text-decoration: none;
    }
  }
  &-pink {
    background-color: $color1;
    border: 2px solid $color1;
    color: #fff;
    &:hover {
      background-color: #fff;
      color: $color1;
      text-decoration: none;
    }
  }
  &-pinkWhite {
    background-color: #fff;
    border: 2px solid $color1;
    color: $color1;
    &:hover {
      background-color: $color1;
      color: #fff;
      text-decoration: none;
    }
  }
  &-transparentsBlack {
    background-color: transparent;
    border: 2px solid $textColor;
    color: $textColor;
    &:hover {
      background-color: $textColor;
      color: #fff;
      text-decoration: none;
    }
  }
  &-transparentswhite {
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    &:hover {
      background-color: #fff;
      color: $color1;
      text-decoration: none;
    }
  }

  &-middle1 {
    min-width: 192px;
    padding: 10px;
    @include f-size();
    @include bpTb {
      min-width: 175px;
      @include f-size($baseFontSp);
    }
  }
  &-middle2 {
    min-width: 240px;
    padding: 10px;
    @include f-size(20);
    @include bpTb {
      min-width: 200px;
      @include f-size(16);
    }
  }
  &-middle3 {
    min-width: 260px;
    padding: 15px 10px;
    @include f-size(15);
    @include bpTb {
      min-width: 210px;
      @include f-size(14);
    }
  }

  &-max {
    width: 100%;
  }

  &-small {
    width: auto;
    min-width: 90px;
  }

  &-arrow-right {
    position: relative;
    padding-right: 30px;
    padding-left: 30px;

    &::after {
      content: '\f054';
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      @include iconFont;
      @include f-size(11);
    }
  }
}


/*
#styleguide
.m-btn1-wrap

.m-btn1を横並びにするクラス
```
<div class="m-btn1-wrap">
  <a a href="" class="m-btn1 m-btn1-en m-btn1-white m-btn1-middle1">MORE</a>
  <a href="" class="m-btn1 m-btn1-en m-btn1-pink">MORE</a>
</div>
```
*/
.m-btn1-wrap {
  text-align: center;

  .m-btn1 {
    &+.m-btn1 {
      margin-left: 12px;

      @include bpTb {
        margin-top: 12px;
        margin-left: 0;
      }
    }
  }

  &-vertical {}
}

/*
#styleguide
.m-btn-text

テキストリンク
```
<a href="#" class="m-btn-text">もっとみる</a>
```
*/
.m-btn-text {
  color: $color1;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

/*
#styleguide
.m-btn2

汎用ボタン2
```
<div class="mB20">
  <a href="" class="m-btn2 m-btn2-middle1 m-btn2-black m-btn2-iconImage m-btn2-arrow m-btn2-arrow-right">
    <span class="m-btn2-en">ABOUT US</span>
    <span class="m-btn2-ja">JDLについて</span>
  </a>

  <a href="" class="m-btn2 m-btn2-middle1 m-btn2-white m-btn2-iconImage m-btn2-arrow m-btn2-arrow-right">
    <span class="m-btn2-en">ABOUT US</span>
    <span class="m-btn2-ja">JDLについて</span>
  </a>

  <a href="" class="m-btn2 m-btn2-middle1 m-btn2-white m-btn2-iconText m-btn2-arrow m-btn2-arrow-bottom">
    <span class="m-btn2-en">LESSON SCHEDULE</span>
    <span class="m-btn2-ja">レッスンスケジュール</span>
  </a>
</div>
```
*/
.m-btn2 {
  position: relative;
  display: inline-block;
  padding: 27.5px 35px 27.5px 85px;
  background-color: transparent;
  text-align: left;
  @include bpTb {
    padding: 22px 35px 22px 70px;
  }
  @include breakpoint(320px) {
    padding: 22px 35px 22px 60px;
  }
  &:hover {
    text-decoration: none;
  }

  &-iconImage {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 25px;
      transform: translateY(-50%);
      background: {
        repeat: no-repeat;
        position: center;
        size: 100%;
      };
      @include bpTb {
        left: 15px;
      }
    }
  }

  &-iconText {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 25px;
      transform: translateY(-50%);
      @include iconFont();
      @include f-size(38);
      font-weight: 900;
      @include bpTb {
        left: 20px;
        @include f-size(25);
      }
    }
  }

  &-arrow {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 20px;
      width: 12px;
      height: 12px;
      @include bpTb {
        width: 9px;
        height: 9px;
        right: 15px;
      }
    }
    &-right {
      &::after {
        transform: rotate(45deg) translateY(-50%);
      }
    }
    &-bottom {
      &::after {
        top: 40%;
        transform: rotate(135deg) translateY(-50%);
      }
    }
  }

  &-middle1 {
    min-width: 400px;
    @include bpTb {
      min-width: 300px
    }
    @include bpSps {
      min-width: $baseWidthSp;
    }
  }
  &-middle2 {
    min-width: 312px;
    padding: 17px 35px 17px 85px;
    @include bpTb {
      min-width: 260px;
      padding: 15px 35px 15px 75px;
    }
    @include bpSps {
      min-width: $baseWidthSp;
    }
  }

  &-en {
    display: block;
    font-family: $Rubik;
    @include f-size(26);
    font-weight: 500;
    line-height: 1.2em;
    letter-spacing: 0.04em;
    white-space: nowrap;
    @include bpTb {
      @include f-size(20);
    }
    @include bpSps {
      @include f-size(18);
    }
  }

  &-ja {
    display: block;
    @include f-size(15);
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: 0.02em;
    @include bpTb {
      @include f-size(13);
    }
  }

  &-black {
    color: $textColor;
    border: 2px solid $textColor;
    background-color: transparent;
    transition: all .4s ease;
    &:hover {
      color: #fff;
      background-color: $textColor;
      &.m-btn2-arrow {
        &::after {
          border-top: solid 2px #fff;
          border-right: solid 2px #fff;
        }
      }
    }

    &.m-btn2-arrow {
      &::after {
        border-top: solid 2px $textColor;
        border-right: solid 2px $textColor;
      }
    }
  }

  &-white {
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    transition: all .4s ease;
    &:hover {
      background-color: #fff;
      color: $textColor;
      &.m-btn2-arrow {
        &::after {
          border-top: solid 3px $textColor;
          border-right: solid 3px $textColor;
        }
      }
    }

    &.m-btn2-arrow {
      &::after {
        border-top: solid 3px #fff;
        border-right: solid 3px #fff;
      }
    }
  }
}


/*
#styleguide
.m-btn3

汎用ボタン3
```
<div class="mB20">
</div>
```
*/
.m-btn3 {
  position: relative;
  display: inline-block;
  padding: 27.5px 35px 27.5px 75px;
  background-color: transparent;
  text-align: left;
  @include bpTb {
    padding: 22px 35px 22px 75px;
  }
  &:hover {
    text-decoration: none;

    &.m-btn3-icon {
      &.instagram {
        &::before {
          background: {
            image: url(../img/icon_instagram_white.svg);
          };
        }
      }
      &.twitter {
        &::before {
          background: {
            image: url(../img/icon_twitter_white.svg);
          };
        }
      }
      &.line {
        &::before {
          background: {
            image: url(../img/icon_line_white.svg);
          };
        }
      }
    }
  }

  &-icon {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 25px;
      transform: translateY(-50%);
      background: {
        repeat: no-repeat;
        position: center;
        size: 100%;
      };
    }
    &.instagram {
      &::before {
        width: 30px;
        height: 30px;
        background: {
          image: url(../img/icon_instagram_black.svg);
        };
      }
    }
    &.twitter {
      &::before {
        width: 31px;
        height: 25px;
        background: {
          image: url(../img/icon_twitter_black.svg);
        };
      }
    }
    &.line {
      &::before {
        width: 30px;
        height: 30px;
        background: {
          image: url(../img/icon_line_black.svg);
        };
      }
    }
  }

  &-arrow {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 20px;
      width: 12px;
      height: 12px;
      @include bpTb {
        right: 15px;
        width: 9px;
        height: 9px;
      }
    }
    &-right {
      &::after {
        transform: rotate(45deg) translateY(-50%);
      }
    }
    &-bottom {
      &::after {
        top: 40%;
        transform: rotate(135deg) translateY(-50%);
      }
    }
  }

  &-middle1 {
    min-width: 312px;
    @include bpTb {
      min-width: 260px;
    }
    @include bpSps {
      min-width: $baseWidthSp;
    }
  }

  &-en {
    font-family: $Rubik;
    @include f-size(24);
    font-weight: 500;
    line-height: 1.2em;
    letter-spacing: 0.04em;
    @include bpTb {
      @include f-size(19);
    }
  }

  &-smallja {
    display: block;
    font-family: $Gothic;
    @include f-size(14);
  }

  &-black {
    color: $textColor;
    border: 2px solid $textColor;
    background-color: transparent;
    transition: all .4s ease;
    &:hover {
      color: #fff;
      background-color: $textColor;
      &.m-btn3-arrow {
        &::after {
          border-top: solid 2px #fff;
          border-right: solid 2px #fff;
        }
      }
    }

    &.m-btn3-arrow {
      &::after {
        border-top: solid 2px $textColor;
        border-right: solid 2px $textColor;
      }
    }
  }

  &-white {
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    transition: all .4s ease;
    &:hover {
      background-color: #fff;
      color: $textColor;
      &.m-btn3-arrow {
        &::after {
          border-top: solid 2px $textColor;
          border-right: solid 2px $textColor;
        }
      }
    }

    &.m-btn3-arrow {
      &::after {
        border-top: solid 2px #fff;
        border-right: solid 2px #fff;
      }
    }
  }
}
