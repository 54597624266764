@charset "UTF-8";
// @import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP&subset=japanese);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap);
@import url(https://use.fontawesome.com/releases/v5.10.2/css/all.css);

@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,500;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500;700&display=swap);

/*********************************************
DEFAULT
*********************************************/
*,
*::before,
*::after {
  box-sizing: border-box;
}
body * {
  -webkit-text-size-adjust: 100%;
}
a img {
  transition-property: opacity;
  transition-duration: .2s;
  backface-visibility: hidden;
}
a:hover {
  text-decoration: underline;
}
a:hover img {
  opacity: .8;
}
iframe[name=google_conversion_frame] {
  position: absolute;
  top: 0;
}
// iOSでのデフォルトスタイルをリセット
input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}
input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none;
}
input[type="submit"]::focus,
input[type="button"]::focus {
  outline-offset: -2px;
}


/*********************************************
Customize
*********************************************/
html {
  font-family: $Gothic;
  font-size: 62.5%;
}
body {
  max-width: $maxWidth;
  margin-right: auto;
  margin-left: auto;
  color: $textColor;
  @include f-size($baseFont);
  line-height: $lineHeight;
  letter-spacing: 0.02em;
  @include bpTb {
    @include f-size($baseFontSp);
  }
}
a {
  color: $textColor;
}
img {
  max-width: 100%;
  height: auto;
}
