@charset "UTF-8";

/*
#overview
layout
*/

// header
.l-header {
  position: fixed;
  z-index: z(header);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 5px;
  background: rgba($color: #fff, $alpha: 0.95);
  @include bpPc {
    padding: 20px;
  }
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: $baseWidth;
    margin-right: auto;
    margin-left: auto;
    &-logo {
      width: 76px;
      height: 32px;
      margin-left: -50px;
      @include bpPc {
        margin-left: 0;
      }
      @include bpPcs {
        width: 73px;
        height: 30px;
      }
      a {
        &:hover {
          text-decoration: none;
        }
      }
    }
    &-menu {
      display: flex;
      align-items: center;
      position: relative;
      margin-right: -80px;
      @include bpPc {
        margin-right: 0;
      }
      &-glnv {
        display: none;
        @include bpPcs {
          position: relative;
          z-index: z(nav);
          display: inline-block;
          width: 32px;
          height: 26px;
          cursor: pointer;
          transition: all .5s;
        }
        span {
          position: absolute;
          left: 0;
          display: inline-block;
          width: 100%;
          height: 3px;
          background-color: $color1;
          transition: all .5s;
          border-radius: 2px;
          &:nth-of-type(1) {
            top: 0;
          }
          &:nth-of-type(2) {
            top: 11px;
          }
          &:nth-of-type(3) {
            bottom: 0;
          }
        }
        &.is-trigger {
          span {
            background-color: #fff;
            &:nth-of-type(1) {
              transform: translateY(11px) rotate(315deg);
            }
            &:nth-of-type(2) {
              opacity: 0;
            }
            &:nth-of-type(3) {
              transform: translateY(-12px) rotate(-315deg);
            }
          }
        }
      }
      &-list {
        @include bpPcs {
          position: fixed;
          top: 0;
          right: 0;
          z-index: 1;
          width: 100vw;
          height: 100vh;
          background: rgba($color: $color1, $alpha: 1);
          pointer-events: none;
          opacity: 0;
          transition: opacity .3s linear;
          overflow-y: scroll;
        }
        .listWrap {
          display: flex;
          align-items: center;
          @include bpPcs {
            display: block;
            position: absolute;
            top: 11.5vh;
            left: 0;
            height: 110%;
            width: calc(100% - 70px);
            margin-right: 35px;
            margin-left: 35px;
          }
          @include bpSps {
            width: calc(100% - 100px);
            margin-right: 50px;
            margin-left: 50px;
          }
          li {
            position: relative;
            line-height: 1;
            text-align: center;
            @include bpPcs {
              width: 100%;
              margin-bottom: 35px;
            }
            .link {
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              width: 100%;
              height: 50px;
              padding-right: 15px;
              padding-left: 15px;
              color: $color1;
              font-family: $Rubik;
              @include f-size(14);
              font-weight: 500;
              letter-spacing: 0.1em;
              text-decoration: none;
              @include bpPc {
                padding-right: 10px;
                padding-left: 10px;
                @include f-size(14);
              }
              @include bpPcs {
                justify-content: flex-start;
                height: auto;
                padding: 0;
                color: #fff;
                @include f-size(23);
              }
              @include bpSp {
                @include f-size(20);
              }
              @include bpSps {
                @include f-size(18);
              }

              &::before {
                content: '';
                position: absolute;
                bottom: 10px;
                left: 50%;
                width: calc(100% - 30px);
                height: 2px;
                background-color: $color1;
                transform: scale(0, 1) translateX(0);
                transform-origin: left top;
                transition: transform .3s;
                @include bpPc {
                  width: calc(100% - 30px);
                }
                @include bpPcs {
                  content: none;
                }
              }

              &:hover {
                opacity: .65;
                &::before {
                  transform: scale(1, 1) translateX(-50%);
                }
              }
            }

            &.contactWrapSp {
              display: none;
              @include bpPcs {
                display: block;
              }
              .m-btn2 {
                display: block;
                margin-bottom: 20px;
                &:hover {
                  &.contact {
                    &::before {
                      background: {
                        image: url(../img/icon_contact_black.png);
                      };
                    }
                  }
                  &.trial {
                    &::before {
                      background: {
                        image: url(../img/icon_trial_black.svg);
                      };
                    }
                  }
                }
                &.contact {
                  &::before {
                    @include bpPcs {
                      width: 40px;
                      height: 29px;
                      background: {
                        image: url(../img/icon_contact_white.png);
                      };
                    }
                    @include bpSps {
                      width: 32px;
                      height: 25px;
                    }
                  }
                }
                &.trial {
                  &::before {
                    @include bpPcs {
                      width: 40px;
                      height: 47px;
                      background: {
                        image: url(../img/icon_trial_white.svg);
                      };
                    }
                    @include bpSps {
                      width: 30px;
                      height: 36px;
                    }
                  }
                }
              }
            }

            &.snsWrapSp {
              display: none;
              @include bpPcs {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 25px;
                padding-bottom: 110px;
              }
              .list {
                padding-right: 35px;
                padding-left: 35px;
                border-right: 2px solid #fff;
                height: 55px;
                @include bpSps {
                  padding-right: 22px;
                  padding-left: 22px;
                  height: 45px;
                }
                &:last-of-type {
                  border-right: none;
                }
                .link {
                  &.instagram {
                    img {
                      display: block;
                      width: 57px;
                      height: 60px;
                      margin-top: -3px;
                    }
                  }
                  &.twitter {
                    img {
                      width: 63px;
                      height: 51px;
                    }
                  }
                  &.line {
                    img {
                      display: block;
                      width: 69px;
                      height: 66px;
                      margin-top: -5px;
                      @include bpSps {
                        margin-top: -8px;
                      }
                    }
                  }
                }
              }
            }

            // サブメニュー PC
            .sublist {
              display: none;
              position: absolute;
              top: 50px;
              left: 50%;
              transform: translateX(-50%);
              width: 175px;
              &-wrap {
                li {
                  width: 100%;
                  background-color: $color1;
                  margin-bottom: 1px;
                  transition: all .3s;
                  &:hover {
                    background-color: #fff;
                    transition: all .3s;
                    .childlink {
                      color: $color1;
                    }
                  }
                  .childlink {
                    display: block;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    color: #fff;
                    @include f-size(13);
                    font-weight: 500;
                    text-decoration: none;
                    &:hover {
                    }
                  }
                }
              }
            }

            // サブメニュー SP
            .sublistSp {
              display: none;
              @include bpPcs {
                display: block;
                width: calc(100% - 40px);
                margin-top: 25px;
                margin-left: 20px;
                margin-right: 20px;
              }
              li {
                position: relative;
                padding-bottom: 25px;
                text-align: left;
                @include bpPcs {
                  margin-bottom: 0;
                  border-bottom: none;
                }
                &::before {
                  content: '−';
                  position: absolute;
                  top: 0;
                  left: -20px;
                  color: #fff;
                  @include f-size(17);
                  font-weight: 500;
                  @include bpSps {
                    @include f-size(16);
                  }
                }
                &:last-child {
                  @include bpPcs {
                    padding-bottom: 0;
                  }
                }
                .childlink {
                  color: #fff;
                  @include f-size(17);
                  font-weight: 500;
                  @include bpSps {
                    @include f-size(15);
                  }
                }
              }
            }
          }
        }
        // trigger
        &.is-trigger {
          pointer-events: auto;
          opacity: 1;
        }
      }
      &-snsWrap {
        display: flex;
        @include bpPcs {
          display: none;
        }
        .list {
          padding-right: 10px;
          padding-left: 10px;
          border-right: 1px solid $color1;
          &:last-of-type {
            border-right: none;
          }
          .link {
            display: block;
            line-height: 1;
            &.instagram {
              img {
                width: 19px;
                height: 20px;
              }
            }
            &.twitter {
              img {
                width: 21px;
                height: 17px;
              }
            }
            &.line {
              img {
                width: 23px;
                height: 22px;
              }
            }
          }
        }
      }
      &-contactWrapPc {
        display: flex;
        align-items: center;
        margin-top: 50px;
        padding-left: 30px;
        @include bpPcs {
          order: -1;
          margin-top: 0;
          margin-right: 10px;
          margin-left: 10px;
        }
        .contactLink {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          width: 90px;
          height: 90px;
          margin-right: 5px;
          margin-left: 5px;
          padding-top: 18px;
          padding-bottom: 18px;
          background-color: #fff;
          border: 2px solid $color1;
          border-radius: 50px;
          text-decoration: none;
          transition: all .3s ease;
          @include bpPcs {
            width: 45px;
            height: auto;
            border: 0;
            border-radius: 0;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: transparent;
          }
          &:hover {
            background-color: $color1;
            @include bpPcs {
              background-color: #fff;
            }
            .contactLink-icon {
              &.contact {
                background: {
                  image: url(../img/icon_contact_white.png);
                };
                @include bpPcs {
                  background: {
                    image: url(../img/icon_contact_pink.png);
                  };
                }
              }
              &.trial {
                background: {
                  image: url(../img/icon_trial_white.svg);
                };
                @include bpPcs {
                  background: {
                    image: url(../img/icon_trial_pink.svg);
                  };
                }
              }
            }
            .contactLink-text {
              color: #fff;
            }
          }
          &-icon {
            width: 100%;
            text-align: center;
            background: {
              repeat: no-repeat;
              position: center;
              size: 100%;
            };
            &.contact {
              width: 35px;
              height: 26px;
              margin-bottom: 8px;
              background: {
                image: url(../img/icon_contact_pink.png);
              };
              @include bpPcs {
                width: 30px;
                height: 22px;
                margin-bottom: 0;
              }
            }
            &.trial {
              width: 31px;
              height: 35px;
              background: {
                image: url(../img/icon_trial_pink.svg);
              };
              @include bpPcs {
                width: 23px;
                height: 27px;
                margin-bottom: 0;
              }
            }
          }
          &-text {
            display: block;
            width: 100%;
            color: $color1;
            font-family: $Rubik;
            @include f-size(12);
            font-weight: 500;
            letter-spacing: 0.06em;
            text-align: center;
            @include bpPcs {
              display: none;
            }
          }
        }
      }
    }
  }
}

.l-main {
  margin-top: 50px;
  overflow: hidden;
}

.l-footer {
  position: relative;
  background-color: #fff;
  &-pagetop {
    display: block;
    position: relative;
    width: 100%;
    height: 60px;
    background-color: $textColor;
    &::before {
      content: '';
      position: absolute;
      top: 40%;
      left: 50%;
      transform: rotate(-45deg) translate(-50%,-50%);
      width: 20px;
      height: 20px;
      border-top: solid 3px #fff;
      border-right: solid 3px #fff;
    }
  }
  &-inner {
    padding-top: 40px;
    padding-bottom: 25px;
    @include bpTb {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    &-logo {
      display: block;
      width: 154px;
      height: 63px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 25px;
      @include bpTb {
        width: 105px;
        height: 42px;
        margin-bottom: 15px;
      }
    }
    &-text {
      @include f-size(12);
      font-weight: 400;
      text-align: center;
      @include bpTb {
        @include f-size(11);
        text-align: left;
      }
      .copyright {
        font-family: $Rubik;
        @include f-size(13);
        @include bpTb {
          @include f-size(11);
        }
      }
    }
  }
}

// 404
.notfoundPage {
  position: relative;
  margin-bottom: 120px;
  @include bpPcs {
    margin-bottom: 85px;
  }
  @include bpTb {
    margin-bottom: 65px;
  }
  &-Title {
    margin-top: 80px;
    padding-bottom: 20px;
    @include f-size(24);
    text-align: center;
    @include bpPcs {
      margin-top: 60px;
      padding-bottom: 15px;
      @include f-size(21);
    }
    @include bpTb {
      margin-top: 40px;
      @include f-size(18);
    }
  }
  &-Text {
    padding-bottom: 75px;
    @include f-size(15);
    font-weight: 400;
    text-align: center;
    line-height: 2em;
    @include bpPcs {
      padding-bottom: 55px;
      @include f-size(13);
    }
    @include bpTb {
      padding-bottom: 40px;
    }
  }
}
