@charset "UTF-8";
/*******************************
Default Mixin
*******************************/
/*----------------------
フォントサイズ「rem」の使用
----------------------*/
@mixin f-size($size: $baseFont) {
  font-size: $size + px;
  font-size: ($size / 10) + rem;
}

/*******************************
Customized Mixin
*******************************/
@mixin wrapper($width: $bpPcs) {
  max-width: $width;
  margin-left: auto;
  margin-right: auto;
  @include breakpoint($baseWidth) {
    max-width: $baseWidthSp;
  }
}

@mixin breakpoint($width: $bpSp) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin bpPc {
  @media screen and (max-width: $bpPc) {
    @content;
  }
}

@mixin bpPcs {
  @media screen and (max-width: $bpPcs) {
    @content;
  }
}

@mixin bpTb {
  @media screen and (max-width: $bpTb) {
    @content;
  }
}

@mixin bpSp {
  @media screen and (max-width: $bpSp) {
    @content;
  }
}

@mixin bpSps {
  @media screen and (max-width: $bpSps) {
    @content;
  }
}

@mixin iconFont() {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  line-height: 1;
}
