@charset "UTF-8";

/*
#overview
base
*/
/*
#colors
@$color1 #ff3882
// @$color2 #f36f2e
@$textColor #000
*/
$color1: #ff3882;
// $color2: #f36f2e;
$textColor: #000;


// font
$baseFont: 16;
$baseFontSp: 14;
$lineHeight: 1.8;
$mincho: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
$Gothic: "Noto Sans JP","ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;

$Lato: "Lato", sans-serif;
$Rubik: "Rubik", sans-serif;
$Roboto: "Roboto", sans-serif;

// コンテンツ幅
$baseWidth: 1024px;
$baseWidthSp: 91.25%;
$baseWidthPadding: 4.375%;

// キャンバスサイズ
$maxWidth: 2000px;

// ブレークポイント
$bpPc: 1500px;
$bpPcs: 1024px;
$bpTb: 768px;
$bpSp: 640px;
$bpSps: 425px;


// z-index
// ↑レイヤー上
// ↓レイヤー下
// eg. z-index: z(child);
$z-index:(
  child,
  nav,
  pulldown,
  header,
  main3,
  main2,
  main
);
// リストを逆転させる
@function list-reverse($map) {
  $result: ();
  @for $i from length($map)*-1 through -1 {
    $result: append($result, nth($map, abs($i)), comma);
  }
  @return $result;
}
$z-index: list-reverse($z-index);

@function z($name) {
  @return index($z-index, $name);
}
