@charset "UTF-8";

// 入力画面
.mw_wp_form_input {
  .is-input-hidden {
    display: none;
  }
}

// 確認画面
.mw_wp_form_confirm {
  .is-input-hidden {
    display: block;
  }
  .is-confirm-hidden {
    display: none;
  }
  .required {
    display: none;
  }
}

// 完了画面
.mw_wp_form_complete {
}

/*
#styleguide
.m-radio-list

汎用ラジオボタン
```
<div class="m-radio-list mB20">
  <span>
    <label>
      <input type="radio">
      <span>男性</span>
    </label>
  </span>
  <span class="mwform-radio-field horizontal-item">
    <label>
      <input type="radio">
      <span class="mwform-radio-field-text">女性</span>
    </label>
  </span>
</div>

<div class="m-radio-list m-radio-list-col2 mB20">
  <span>
    <label>
      <input type="radio">
      <span>男性</span>
    </label>
  </span>
  <span class="mwform-radio-field horizontal-item">
    <label>
      <input type="radio">
      <span class="mwform-radio-field-text">女性</span>
    </label>
  </span>
</div>
```

*/
.m-radio-list {
  display: flex;
  flex-wrap: wrap;

  @include bpTb {
    flex-direction: column;
  }

  label {
    display: inline-block;
  }

  &>span {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 5px;
    margin-left: 0 !important;
    padding-right: 60px;
    padding-left: 20px;

    span {
      display: inline-block;
      vertical-align: middle;
      line-height: 1.4;
    }
  }

  input[type=radio] {
    display: none;

    &+span::before,
    &+span::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
    }

    &+span::before {
      left: 0;
      background-color: #fff;
      border: 1px solid #b7b6b5;
      width: 15px;
      height: 15px;
    }

    &+span::after {
      opacity: 0;
      left: 4px;
      background-color: $color1;
      width: 7px;
      height: 7px;
    }

    &:checked+span::before {
      background-color: #fff;
    }

    &:checked+span::after {
      opacity: 1;
    }
  }

  &-col2 {
    &>span {
      width: 50%;
      padding-right: 20px;

      @include bpTb {
        width: 100%;
      }
    }
  }

  &-col4 {
    &>span {
      width: 25%;
      padding-right: 20px;

      @include bpTb {
        width: 100%;
      }
    }
  }
}


/*
#styleguide
.m-checkbox-list

汎用チェックボックス
```
<div class="m-checkbox-list mB20">
  <span>
    <label>
      <input type="checkbox">
      <span>男性</span>
    </label>
  </span>
  <span class="mwform-checkbox-field horizontal-item">
    <label>
      <input type="checkbox">
      <span>女性</span>
    </label>
  </span>
</div>

<div class="m-checkbox-list m-checkbox-list-col4 mB20">
  <span>
    <label>
      <input type="checkbox">
      <span>男性</span>
    </label>
  </span>
  <span class="mwform-checkbox-field horizontal-item">
    <label>
      <input type="checkbox">
      <span>女性</span>
    </label>
  </span>
</div>
```
*/
.m-checkbox-list {
  display: flex;
  flex-wrap: wrap;

  @include bpTb {
    padding-top: 10px;
    padding-bottom: 10px;
    flex-direction: column;
  }

  &>span {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 5px;
    padding-right: 60px;
    padding-left: 24px;

    span {
      display: inline-block;
      vertical-align: middle;
      line-height: 1.4;
    }
  }

  label {
    display: inline-block;
  }

  input[type=checkbox] {
    display: none;

    &+span::before,
    &+span::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &+span::before {
      left: 0;
      background-color: #fff;
      border: 1px solid #b7b6b5;
      border-radius: 3px;
      width: 18px;
      height: 18px;
    }

    &+span::after {
      opacity: 0;
      left: 6px;
      transform: translateY(-60%) rotate(45deg);
      border-right: 2px solid $color1;
      border-bottom: 2px solid $color1;
      width: 6px;
      height: 11px;
    }

    &:checked+span::before {
      background-color: #fff;
    }

    &:checked+span::after {
      opacity: 1;
    }
  }

  &-col2 {
    &>span {
      width: 50%;
      padding-right: 20px;

      @include bpTb {
        width: 100%;
      }
    }
  }

  &-col4 {
    &>span {
      width: 25%;
      padding-right: 20px;

      @include bpTb {
        width: 100%;
      }
    }
  }
}
