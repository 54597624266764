@charset "UTF-8";

/*
#styleguide
.m-title1
タイトルのクラス
```
<h2 class="m-title1 m-title1-center m-title1-borderPink">
  <span class="m-title1-en m-title1-italic">INSTRUCTOR</span>
  <span class="m-title1-ja">講師紹介</span>
</h2>
```
*/
.m-title1 {
  display: block;
  position: relative;

  &-center {
    text-align: center;
  }

  &-white {
    color: #fff;
  }

  &-borderPink {
    &::after {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      width: 60px;
      height: 3px;
      background-color: $color1;
      @include bpTb {
        bottom: -15px;
      }
    }
  }

  &-en {
    display: block;
    font-family: $Rubik;
    @include f-size(54);
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.1em;
    @include bpTb {
      @include f-size(30);
    }
  }
  &-ja {
    @include f-size(22);
    letter-spacing: 0.06em;
    font-weight: 700;
    @include bpTb {
      @include f-size(13);
    }
  }

  &-italic {
    font-style: italic;
  }
}

/*
#styleguide
.m-title2
タイトルのクラス
```
<h2 class="m-title2 m-title2-pink">ダンス初心者の方のための、ダンススクールです！</h2>
```
*/
.m-title2 {
  @include f-size(26);
  font-weight: 700;
  @include bpTb {
    @include f-size(17);
  }

  &-center {
    text-align: center;
  }

  &-black {
    color: $textColor;
  }
  &-pink {
    color: $color1;
  }
}

.m-title3 {
  display: flex;
  align-items: center;
  @include bpTb {
    display: block;
    text-align: center;
  }
  &-center {
    justify-content: center;
  }
  &-left {
    justify-content: flex-start;
  }
  &-right {
    justify-content: flex-end;
  }

  &-en {
    font-family: $Rubik;
    @include f-size(50);
    font-weight: 500;
    line-height: 1.4em;
    letter-spacing: 0.1em;
    @include bpTb {
      display: block;
      @include f-size(30);
    }
  }
  &-ja {
    letter-spacing: 0.02em;
    @include f-size(18);
    font-weight: 500;
    @include bpTb {
      @include f-size(13);
    }
  }

  .space {
    padding-right: 20px;
    @include bpTb {
      padding-right: 0;
    }
  }

  &-italic {
    font-style: italic;
  }
}
