@charset "UTF-8";

/*
#styleguide
.m-wrapper

中央寄せのクラス
```
<div class="m-wrapper">
デフォルトmax-width: 1024px;
</div>
```
*/
.m-wrapper {
  @include wrapper;
}

/*
#styleguide
.m-telLink

電話番号のリンク
768pxからクリック（タップ）可能に
```
<a href="tel:" class="m-telLink">電話番号</a>
```
*/
.m-telLink {
  pointer-events: none;
  @include bpTb {
    pointer-events: auto;
    text-decoration: underline;
  }
}

/*
#styleguide
.fadeTop

フェード 各種
```
<div class="fadeTop">テスト</div>

*/
.fadeTop {
  opacity: 0;
  transform: translateY(10px);
  transition: all .5s ease;
  backface-visibility: hidden;
  &.is-active {
    opacity: 1;
    transform: translateY(0);
  }
}

/*
#styleguide
.m-visual

下層 メインビジュアル
```
<div class="m-visual event"></div>

*/
.m-visual {
  position: relative;
  height: 331px;
  background: {
    repeat: no-repeat;
    position: center;
  };
  @include bpSp {
    height: auto;
    background-size: cover;
  }
  &.concept {
    background-image: url(../img/concept/mv.jpg);
    @include bpSp {
      background-image: url(../img/concept/mv_sp.jpg);
    }
  }
  &.class {
    background-image: url(../img/class/mv.jpg);
    @include bpSp {
      background-image: url(../img/class/mv_sp.jpg);
    }
  }
  &.price {
    background-image: url(../img/price/mv.jpg);
    @include bpSp {
      background-image: url(../img/price/mv_sp.jpg);
    }
  }
  &.questions {
    background-image: url(../img/questions/mv.jpg);
    @include bpSp {
      background-image: url(../img/questions/mv_sp.jpg);
    }
  }
  &.event {
    background-image: url(../img/event/mv.jpg);
    @include bpSp {
      background-image: url(../img/event/mv_sp.jpg);
    }
  }
  &.news {
    background-image: url(../img/news/mv.jpg);
    @include bpSp {
      background-image: url(../img/news/mv_sp.jpg);
    }
  }
  &.contact,
  &.notfound {
    background-image: url(../img/contact/mv.jpg);
    @include bpSp {
      background-image: url(../img/contact/mv_sp.jpg);
    }
  }
  &.trial {
    background-image: url(../img/trial/mv.jpg);
    @include bpSp {
      background-image: url(../img/trial/mv_sp.jpg);
    }
  }
  &.schedule {
    background-image: url(../img/schedule/mv.jpg);
    @include bpSp {
      background-image: url(../img/schedule/mv_sp.jpg);
    }
  }
  .m-wrapper {
    position: relative;
    height: inherit;
    @include bpSp {
      height: auto;
      padding-bottom: 62.5%;
    }
  }
  &-title {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    color: #fff;
    @include bpPc {
      left: 50px;
    }
    @include bpSp {
      left: $baseWidthPadding;
    }
    .en {
      display: block;
      font-family: $Rubik;
      font-style: italic;
      @include f-size(70);
      letter-spacing: 0.1em;
      line-height: 1;
      @include bpPc {
        @include f-size(60);
      }
      @include bpSp {
        @include f-size(35);
      }
    }
    .ja {
      display: block;
      @include f-size(22);
      font-weight: 700;
      @include bpPc {
        @include f-size(19);
      }
      @include bpSp {
        @include f-size(13);
      }
    }
  }
}

/*
#styleguide
.m-breadbrumb

下層 パンくずリスト
```
<ul class="m-breadbrumb m-wrapper">
  <li><a href="../../../">ホーム</a></li>
  <li>イベント･ワークショップ</li>
</ul>
*/
.m-breadbrumb {
  display: flex;
  position: relative;
  z-index: z(main3);
  margin-top: -25px;
  @include bpSp {
    flex-wrap: wrap;
    margin-top: 10px;
  }
  li {
    font-family: $Gothic;
    @include f-size(13);
    &:not(:first-child) {
      &::before {
        content: '＞';
        display: inline-block;
        margin-right: 12px;
        margin-left: 12px;
        @include f-size(10);
        @include bpTb {
          margin-right: 8px;
          margin-left: 8px;
        }
      }
    }
  }
}


/*
#styleguide
.m-newsLayout1

お知らせレイアウト
```
<ul class="m-newsLayout1">
  <li class="m-newsLayout1-list">
    <a href="・./" class="link">
      <div class="link-thumbnail"><img src="./assets/img/img_thumbnail.jpg" alt="サムネイル画像"></div>
      <div class="link-infoarea">
        <p class="link-infoarea-category">お知らせ</p>
        <time datetime="2020-12-10" class="link-infoarea-datetime">2020/12/10</time>
      </div>
      <p class="link-title">タイトルが入りますタイトルが入りますタイトルが入りますタ…</p>
    </a>
  </li>
</ul>
```
*/
.m-newsLayout1 {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  @include bpTb {
    justify-content: space-between;
  }
  &-list {
    width: 23.5%;
    margin-right: 2%;
    @include bpTb {
      width: 47.5%;
      margin-right: 0;
    }
    &:nth-child(4n) {
      margin-right: 0;
    }
    &:nth-child(n+5) {
      padding-top: 50px;
    }
    &:nth-child(n+3) {
      @include bpTb {
        padding-top: 30px;
      }
    }
    &:nth-child(even) {
      @include bpTb {
        margin-right: 0;
      }
    }
    .link {
      text-decoration: none;
      &-thumbnail {
        padding-bottom: 15px;
        img {
          width: 100%;
          height: 235px;
          object-fit: cover;
          font-family: 'object-fit: cover';
          border-radius: 5px;
          @include bpPcs {
            height: 180px;
          }
          @include bpTb {
            height: 200px;
          }
          @include bpSps {
            height: 140px;
          }
        }
      }
      &-infoarea {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 5px;
        &-category {
          width: 65%;
          max-width: 12em;
          margin-right: 20px;
          background-color: $color1;
          color: #fff;
          @include f-size(13);
          font-weight: 500;
          text-align: center;
          @include bpTb {
            margin-right: 15px;
            @include f-size(11);
          }
          @include bpSps {
            @include f-size(10);
          }
          &.black {
            background-color: $textColor;
          }
        }
        &-datetime {
          display: block;
          width: 35%;
          margin-right: 10px;
          @include f-size(13);
          line-height: 1.2em;
          @include bpTb {
            margin-right: 0;
            @include f-size(11);
          }
          @include bpSps {
            @include f-size(10);
          }
        }
      }
      &-title {
        @include f-size(15);
        letter-spacing: 0.02em;
        @include bpTb {
          @include f-size(13);
          line-height: 1.6em;
        }
      }
    }
  }
}

/* ページナビ（一覧） */
.m-pagenavi {
  .wp-pagenavi {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
  }
  .current,
  .extend,
  .page {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 40px;
    padding: 5px 10px;
    @include bpTb {
      width: 30px;
    }
  }
  .current,
  .page:hover {
    background-color: #f7f7f7;
  }
  .prev,
  .next {
    width: 90px;
    height: 28px;
    @include bpTb {
      width: 65px;
      height: 20px;
    }
    @include bpSps {
      width: 50px;
    }
  }
  .previouspostslink,
  .nextpostslink {
    display: inline-block;
    position: relative;
    width: 70px;
    height: 25px;
    @include f-size(15);
    @include bpTb {
      width: 55px;
      height: 20px;
      @include f-size(13);
    }
    @include bpSps {
      width: 40px;
    }
    &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      margin: auto;
      vertical-align: middle;
    }
  }
  .previouspostslink {
    margin-right: 20px;
    text-align: left;
    @include bpTb {
      margin-right: 10px;
    }
    &::after {
      top: 45%;
      left: -20px;
      transform: rotate(225deg);
      border-top: solid 1px $textColor;
      border-right: solid 1px $textColor;
      @include bpTb {
        left: -15px;
      }
    }
  }
  .nextpostslink {
    margin-left: 25px;
    text-align: right;
    @include bpTb {
      margin-left: 10px;
    }
    &::after {
      top: 43%;
      right: -20px;
      transform: rotate(-315deg);
      border-top: solid 1px $textColor;
      border-right: solid 1px $textColor;
      @include bpTb {
        right: -15px;
      }
    }
  }
}

/*
#styleguide
#tblForm

プラポリ
```
<table id="tblForm" class="confirmDisplayNone">
<tbody>
  <tr>
    <td>
      JDL DANCE STUDIO(以下、当スクール)では、個人情報に関する法令およびその他の規範を遵守し、適正に管理し、 お客様の個人情報保護に配慮致します。<br>
      <br>
      １．個人情報の収集について<br>
      // 省略
    </td>
  </tr>
</tbody>
</table>
```
*/

.tblFormWrap {
  position: relative;
  background-color: #fff;
  &::after {
    content: '';
    position: absolute;
    bottom: 1px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 35px);
    height: 35px;
    background-color: #fff;
    @include bpTb {
      height: 25px;
    }
  }
}
#tblForm {
  display: block;
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  margin-bottom: 55px;
  padding: 35px 50px;
  border: 1px solid #cccccc;
  @include bpPcs {
    margin-bottom: 45px;
    padding: 35px;
  }
  @include bpTb {
    margin-bottom: 35px;
    padding: 25px;
  }
  td {
    @include f-size(13);
    line-height: 2em;
    @include bpTb {
      @include f-size(12);
      line-height: 1.8em;
    }
    strong {
      @include f-size(18);
      font-weight: 500;
      @include bpPcs {
        @include f-size(15);
      }
    }
  }
}


/*
#styleguide
.m-otherinner

汎用パーツ1
```
<section class="m-otherinner">
  <div class="m-otherinner-btn contact">
    <div class="icon"><img src="../assets/img/icon_contact_black.png" alt=""></div>
    <h2 class="title">お問い合わせ</h2>
    <p class="text">レッスンについて、仕事依頼など</p>
    <a href="../contact/" class="m-btn1 m-btn1-en m-btn1-transparentsBlack m-btn1-middle2">
      CONTACT
    </a>
  </div>
  <div href="../trial/" class="m-otherinner-btn trial">
    <div class="icon"><img src="../assets/img/icon_trial_white.svg" alt=""></div>
    <h2 class="title">体験レッスン申込み</h2>
    <p class="text">今すぐ体験してみよう！</p>
    <a href="../trial/" class="m-btn1 m-btn1-en m-btn1-transparentswhite m-btn1-middle2">
      TRIAL
    </a>
  </div>
</section>
```
*/
.m-otherinner {
  display: flex;
  @include bpTb {
    flex-wrap: wrap;
  }
  &-btn {
    width: 50%;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    @include bpTb {
      width: 100%;
      padding-top: 30px;
      padding-bottom: 30px;
    }
    &.contact {
      background-color: #f7f7f7;
      .icon {
        margin-bottom: 10px;
      }
    }
    &.trial {
      background-color: $color1;
      color: #fff;
      .icon {
        width: 46px;
        height: 52px;
        margin-right: auto;
        margin-left: auto;
      }
    }
    .icon {
    }
    .title {
      @include f-size(24);
      font-weight: 700;
      @include bpPcs {
        @include f-size(20);
      }
      @include bpTb {
        @include f-size(17);
      }
    }
    .text {
      margin-bottom: 20px;
      @include f-size(15);
      font-weight: 500;
      @include bpPcs {
        @include f-size(13);
      }
    }
    .btn {
    }
  }
}


/* 汎用クラス */
/* -------------------------------------------------- */
.mp0 {margin: 0px;padding: 0px;}
.p0 {padding:0px !important;}
.m0{margin:0px !important;}

.pT0 {padding-top:0px !important;}
.pT5 {padding-top:5px !important;}
.pT10 {padding-top:10px !important;}
.pT15 {padding-top:15px !important;}
.pT20 {padding-top:20px !important;}
.pT30 {padding-top:30px !important;}
.pT40 {padding-top:40px !important;}
.pT50 {padding-top:50px !important;}
.pT60 {padding-top:60px !important;}

.pB5 {padding-bottom:5px !important;}
.pB10 {padding-bottom:10px !important;}
.pB20 {padding-bottom:20px !important;}
.pB30 {padding-bottom:30px !important;}
.pB40 {padding-bottom:40px !important;}
.pB50 {padding-bottom:50px !important;}
.pB60 {padding-bottom:60px !important;}

.pL5 {padding-left:5px !important;}
.pL10 {padding-left:10px !important;}
.pL20 {padding-left:20px !important;}
.pL30 {padding-left:30px !important;}
.pL40 {padding-left:40px !important;}
.pL50 {padding-left:50px !important;}
.pL60 {padding-left:60px !important;}

.pR0 {padding-right:0px !important;}
.pR10 {padding-right:10px !important;}
.pR20 {padding-right:20px !important;}
.pR30 {padding-right:35px !important;}
.pR40 {padding-right:20px !important;}
.pR50 {padding-right:55px !important;}
.pR60 {padding-right:55px !important;}

.mT0 {margin-top:0px !important;}
.mT5 {margin-top:5px !important;}
.mT10 {margin-top:10px !important;}
.mT15 {margin-top:15px !important;}
.mT20 {margin-top:20px !important;}
.mT25 {margin-top:25px !important;}
.mT30 {margin-top:30px !important;}
.mT40 {margin-top:40px !important;}
.mT50 {margin-top:50px !important;}
.mT60 {margin-top:60px !important;}

.mB0 {margin-bottom:0px !important;}
.mB10 {margin-bottom:10px !important;}
.mB20 {margin-bottom:20px !important;}
.mB25 {margin-bottom:25px !important;}
.mB30 {margin-bottom:30px !important;}
.mB40 {margin-bottom:40px !important;}
.mB50 {margin-bottom:50px !important;}
.mB60 {margin-bottom:60px !important;}

.mL0 {margin-left: 0px !important;}
.mL10 {margin-left: 10px !important;}
.mL15 {margin-left: 15px !important;}
.mL20 {margin-left: 20px !important;}
.mL30 {margin-left: 30px !important;}

.mR0 {margin-right: 0px !important;}
.mR5 {margin-right: 5px !important;}
.mR10 {margin-right: 10px !important;}
.mR20 {margin-right: 20px !important;}
.mR30 {margin-right: 30px !important;}
.mR40 {margin-right: 40px !important;}

.tCenter {text-align:center !important;}
.tLeft {text-align:left !important;}
.tRight {text-align:right !important;}

.fLeft{float:left !important;}
.fRight{float:right !important;}
