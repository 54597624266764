@charset "UTF-8";

/*******************************
Style Start
*******************************/
.is-pc-hidden {
  display: none;
  @include bpTb {
    display: inline-block;
  }
}

.is-sp-hidden {
  display: inline-block;
  @include bpTb {
    display: none;
  }
}
